
/deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
}

.dataAnalysisTemplate {
    width: 100%;
    height: 100%;
    display: flex;

    .dataAnalysisTemplate_left {
        width: 131px;
        background-color: #F2F3FA;
        padding-top: 62px;
        z-index: -1;
        display: flex;
        flex-direction: column;

        .icon_box {
            text-align: center;

            .point_box {
                display: flex;
                padding: 0 0 10px 0;
                flex-direction: column;
                align-items: center;

                .point {
                    width: 4px;
                    height: 4px;
                    background-color: #C6C6C6;
                    border-radius: 50%;
                    margin-top: 10px;
                }
            }
        }
    }

    .dataAnalysisTemplate_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .dataAnalysisTemplate_right_one {
            font-size: 18px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 500;
            color: #33475B;
            display: inline-flex;
            flex-direction: row;
            height: 18px;
            align-items: center;
            margin-left: 50px;
            margin-top: 60px;
        }

        .dataAnalysisTemplate_right_two {
            display: inline-flex;
            flex-direction: row;
            margin-top: 40px;
            margin-left: 30px;

            .dataAnalysisTemplate_right_two_title {
                min-width: 30px;
                font-size: 20px;
                line-height: 20px;
                font-family: PingFang SC-Regular, PingFang SC;
                color: #333333;
                margin-right: 20px;
                font-weight: 700;
            }

            .dataAnalysisTemplate_right_two_content {
                font-weight: 700;
                align-items: center;
                width: 1000px;
                font-size: 18px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 500;
                color: #333333;
                word-break: break-all;
                word-wrap: break-word;
            }
        }

        .dataAnalysisTemplate_right_three {
            display: inline-flex;
            flex-direction: row;

            .dataAnalysisTemplate_right_three_title {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-right: 20px;
                width: 30px;
            }

            .dataAnalysisTemplate_right_three_content {
                width: 1000px;
            }
        }

        .dataAnalysisTemplate_right_four {
            margin-left: 115px;
            margin-top: 62px;
            display: inline-flex;
            flex-direction: row;

            .dataAnalysisTemplate_right_four_btn {
                width: 84px;
                height: 40px;
                border: 1px solid #F23B2F;
                border-radius: 4px;
                text-align: center;
                line-height: 40px;
                margin-right: 24px;
                cursor: pointer;
                margin-bottom: 50px;
            }

            .dataAnalysisTemplate_right_four_btn1 {
                color: #F23B2F;
                font-size: 14px;
            }

            .dataAnalysisTemplate_right_four_btn2 {
                background-color: #F23B2F;
                color: #FFFFFF;
            }
        }
    }

    .mt {
        margin-top: 53px;
        margin-left: 68px;
    }

    .activeColor {
        color: #F23B2F !important;
    }

    .activeBgColor {
        background-color: #F23B2F !important;
    }
}

/*/deep/ .tox .tox-notifications-container .tox-notification {*/
/*    display: none;*/
/*}*/
